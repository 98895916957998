import {createStore} from "vuex";
import {ReactiveRequerimentsPOI} from "@/types/ReactiveRequerimentsPOI";
import {PVModule, PVModuleManufacturerSimple, PVModuleSimple} from "@/types/PVModule";
import {Inverter, InverterFamily, InverterSimple} from "@/types/Inverter";
import {AnalysisConditions, Plantdata, PlantSizingItemDTO, PVModuleSettings} from "@/types/PlantUIParameters";
import {TransformerAndWiring} from "@/types/TransformerAndWiring";
import {LowVoltagePlantSizingWizardSteps, MediumVoltageSizingWizardSteps} from "@/types/PlantSizingWizardSteps";
import {TapChanger} from "@/types/PVGenerator";
import {PVGeneratorConfig, PVGeneratorConfigBasic} from "@/types/PlantSizingData";
import {HasPlantOrSizingStatus, PlantStatus, PVModuleStatus, VoltageLevel} from "@/types/plantdata";
import {
    CloneSizingStatus,
    PlantInfoDialogStatus,
    PVModuleDialogStatus,
    PVModuleFilter,
    SizingStatus,
    SizingValuesStatus
} from "@/types/SizingData";
import {VariableUnits} from "@/types/GeneralVariables";
import {DataList} from "@/types/DataList";
import {LoginUserFormStatus, User} from "@/types/User";

export type StateUser = {
    User: User,
    UserToken: string
    ISMToken: string
};

export const defaultStateUser = () => {
    return {
        User: {} as User,
        UserToken: "",
        ISMToken: "",
    }
};

export const stateUser: StateUser = defaultStateUser();

const moduleUser = {
    state: stateUser,
    mutations: {
        setUser(state: StateUser, user: User) {
            state.User = user;
        },

        setUserToken(state: StateUser, token: string) {
            state.UserToken = token;
        },

        setISMToken(state: StateUser, token: string) {
            state.ISMToken = token;
        },

        resetStateUser(state: StateUser) {
            Object.assign(state, defaultStateUser());
        }
    },
    getters: {
        getUser(state: StateUser) {
            return state.User;
        },

        getUserId(state: StateUser) {
            return state.User.id;
        },

        getUserToken(state: StateUser) {
            return state.UserToken;
        },

        getISMToken(state: StateUser) {
            return state.ISMToken;
        }
    }
};

export type StatePlant = {
    PlantData: Plantdata
};

export const defaultStatePlant = () => {
    return {
        PlantData: {} as Plantdata
    }
};

export const statePlant: StatePlant = defaultStatePlant();

const modulePlant = {
    state: statePlant,
    mutations: {
        setPlantData(state: StatePlant, plantdata: Plantdata) {
            state.PlantData = plantdata;
        },

        resetStatePlant(state: StatePlant) {
            Object.assign(state, defaultStatePlant());
        }
    },
    getters: {
        getPlantData(state: StatePlant) {
            return state.PlantData;
        },

        getVoltageLevelPlantData(state: StatePlant) {
            return state.PlantData?.plantDefinition?.voltageLevel;
        },

        isMediumVoltageLevelPlantData(state: StatePlant) {
            return false;
            // return state.PlantData?.plantDefinition?.voltageLevel === VoltageLevel.MediumVoltage;
        },

        isLowVoltageLevelPlantData(state: StatePlant) {
            return state.PlantData?.plantDefinition?.voltageLevel === VoltageLevel.LowVoltage;
        }
    }
};

export type StatePVModuleSection = {
    PVModuleManufacturerSelected: number;
    PVModuleId: number;
    PVModuleStatus: PVModuleStatus;
};

export const defaultStatePVModuleSection = () => {
    return {
        PVModuleManufacturerSelected: 0,
        PVModuleId: 0,
        PVModuleStatus: PVModuleStatus.WithoutStatus
    }
};

export const statePVModuleSection: StatePVModuleSection = defaultStatePVModuleSection();

const modulePVModule = {
    state: statePVModuleSection,
    mutations: {
        setPVModuleManufacturerSelected(state: StatePVModuleSection, pvmoduleid: number) {
            state.PVModuleManufacturerSelected = pvmoduleid;
        },

        setPVModuleId(state: StatePVModuleSection, pvmoduleid: number) {
            state.PVModuleId = pvmoduleid;
        },

        setPVModuleStatus(state: StatePVModuleSection, pvmodulestatus: PVModuleStatus) {
            state.PVModuleStatus = pvmodulestatus;
        },

        resetPVModuleId(state: StatePVModuleSection) {
            state.PVModuleId = 0;
        },

        resetStatePVModuleSection(state: StatePVModuleSection) {
            Object.assign(state, defaultStatePVModuleSection());
        }
    },
    getters: {
        getPVModuleManufacturerSelected(state: StatePVModuleSection) {
            return state.PVModuleManufacturerSelected;
        },

        getPVModuleId(state: StatePVModuleSection) {
            return state.PVModuleId;
        },

        getPVModuleStatus(state: StatePVModuleSection) {
            return state.PVModuleStatus;
        },

        isRemovePVModuleStatus(state: StatePVModuleSection) {
            return state.PVModuleStatus === PVModuleStatus.RemovePVModule;
        },
    }
};

export type AppState = {
    PVModuleManufacturers: DataList[]
    PVModuleTypes: DataList[],
    SizingListPaginatorSelectedPage: number,
    PlantListPaginatorSelectedPage: number
}

export const defaultAppState = () => {
    return {
        PVModuleManufacturers: [],
        PVModuleTypes: [],
        SizingListPaginatorSelectedPage: 0,
        PlantListPaginatorSelectedPage: 0
    }
};

export const AppState: AppState = defaultAppState();

const moduleAppState = {
    state: AppState,
    mutations: {
        setPVModuleManufacturers(state: AppState, pvmodulemanufacturers: DataList[]) {
            state.PVModuleManufacturers = pvmodulemanufacturers;
        },

        setPVModuleTypes(state: AppState, pvmoduletypes: DataList[]) {
            state.PVModuleTypes = pvmoduletypes;
        },

        resetAppState(state: AppState) {
            Object.assign(state, defaultAppState());
        },

        setSizingListPaginatorSelectedPage(state: AppState, page: number) {
            state.SizingListPaginatorSelectedPage = page;
        },

        setPlantListPaginatorSelectedPage(state: AppState, page: number) {
            state.PlantListPaginatorSelectedPage = page;
        }
    },
    getters: {
        getPVModuleManufacturers(state: AppState): DataList[] {
            return state.PVModuleManufacturers;
        },

        getPVModuleTypes(state: AppState): DataList[] {
            return state.PVModuleTypes;
        },
        getSizingListPaginatorSelectedPage(state: AppState) {
            return state.SizingListPaginatorSelectedPage;
        },

        getPlantListPaginatorSelectedPage(state: AppState) {
            return state.PlantListPaginatorSelectedPage;
        },
    }
};

// export type SizingResult = {
//     PlantGeneralDatas: SectionBlockDataByLevel[];
//     SizingInverterData: Inverter;
//     SizingInverter: SectionBlockDataByLevel[];
//     SizingDesignParams: SectionBlockDataByLevel[];
//     SizingPVModuleData: SectionBlockDataByLevel[];
//     SizingPVGenerator: SectionBlockDataByLevel[];
//     SizingConfigCheckByInverter: SectionBlockDataByLevel[];
//     PVTotalConfigurationAndInvertersNumber: SectionBlockDataByLevel[];
//     PVGeneratorCalculatedScatterChartDataLowVoltage: ScatterChartData[];
//     InverterIcon: string;
// }
//
// export const defaultSizingResult = () => {
//     return {
//         PlantGeneralDatas: [],
//         SizingInverterData: {} as Inverter,
//         SizingInverter: [],
//         SizingDesignParams: [],
//         SizingPVModuleData: [],
//         PVTotalConfigurationAndInvertersNumber: [],
//         SizingPVGenerator: [],
//         SizingConfigCheckByInverter: [],
//         PVGeneratorCalculatedScatterChartDataLowVoltage: [],
//         InverterIcon: ''
//     }
// }

// export const SizingResult: SizingResult = defaultSizingResult();

// const moduleSizingResult = {
//     state: SizingResult,
//     mutations: {
//         resetSizingResult(state: SizingResult) {
//             Object.assign(state, defaultSizingResult());
//         },
//
//         setPlantGeneralDatas(state: SizingResult, data: SectionBlockDataByLevel[]) {
//             state.PlantGeneralDatas = data;
//         },
//
//         setSizingInverter(state: SizingResult, data: SectionBlockDataByLevel[]) {
//             state.SizingInverter = data;
//         },
//
//         setSizingInverterData(state: SizingResult, data: Inverter) {
//             state.SizingInverterData = data;
//         },
//
//         setSizingDesignParams(state: SizingResult, data: SectionBlockDataByLevel[]) {
//             state.SizingDesignParams = data;
//         },
//
//         setSizingPVModuleData(state: SizingResult, data: SectionBlockDataByLevel[]) {
//             state.SizingPVModuleData = data;
//         },
//
//         setPVTotalConfigurationAndInvertersNumber(state: SizingResult, data: SectionBlockDataByLevel[]) {
//             state.PVTotalConfigurationAndInvertersNumber = data;
//         },
//
//         setSizingPVGenerator(state: SizingResult, data: SectionBlockDataByLevel[]) {
//             state.SizingPVGenerator = data;
//         },
//
//         setSizingConfigCheckByInverter(state: SizingResult, data: SectionBlockDataByLevel[]) {
//             state.SizingConfigCheckByInverter = data;
//         },
//
//         setPVGeneratorCalculatedScatterChartDataLowVoltage(state: SizingResult, data: ScatterChartData[]) {
//             state.PVGeneratorCalculatedScatterChartDataLowVoltage = data;
//         },
//
//         setInverterIcon(state: SizingResult, data: string) {
//             state.InverterIcon = data;
//         }
//     },
//     getters: {
//         getPlantGeneralDatas(state: SizingResult) {
//             return state.PlantGeneralDatas;
//         },
//
//         getSizingInverter(state: SizingResult) {
//             return state.SizingInverter;
//         },
//
//         getSizingInverterData(state: SizingResult) {
//             return state.SizingInverterData;
//         },
//
//         getSizingDesignParams(state: SizingResult) {
//             return state.SizingDesignParams;
//         },
//
//         getSizingPVModuleData(state: SizingResult) {
//             return state.SizingPVModuleData;
//         },
//
//         getPVTotalConfigurationAndInvertersNumber(state: SizingResult) {
//             return state.PVTotalConfigurationAndInvertersNumber;
//         },
//
//         getSizingPVGenerator(state: SizingResult) {
//             return state.SizingPVGenerator;
//         },
//
//         getSizingConfigCheckByInverter(state: SizingResult) {
//             return state.SizingConfigCheckByInverter;
//         },
//
//         getPVGeneratorCalculatedScatterChartDataLowVoltage(state: SizingResult) {
//             return state.PVGeneratorCalculatedScatterChartDataLowVoltage;
//         },
//
//         getInverterIcon(state: SizingResult) {
//             return state.InverterIcon;
//         }
//     }
// };

export type StateSizing = {
    SizingName: string,
    PVModule: PVModuleSimple,
    PVModuleData: PVModule,
    PVModuleFilter: PVModuleFilter,
    PVModuleManufacturer: PVModuleManufacturerSimple,
    PVModuleManufacturerName: string,
    InverterFamily: InverterFamily,
    InverterFamiles: InverterFamily[],
    Inverter: InverterSimple,
    InverterData: Inverter,
    AnalysisConditions: AnalysisConditions,
    PVModuleSettings: PVModuleSettings,
    PlantSizingWizardStep: LowVoltagePlantSizingWizardSteps | MediumVoltageSizingWizardSteps,
    ReactiveRequerimentsPOI: ReactiveRequerimentsPOI,
    ReactiveRequerimentsPOIName: string,
    TransformerAndWiring: TransformerAndWiring,
    TapChanger: TapChanger,
    InverterTotalNumToAnalyzeFirst: number,
    InverterTotalNumToAnalyze: number,
    PVGeneratorMPPTConfigFirst: PVGeneratorConfigBasic[],
    // PVGeneratorMPPT1ConfigFirst: PVGeneratorConfigBasic,
    // PVGeneratorMPPT2ConfigFirst: PVGeneratorConfigBasic,
    PVGeneratorMPPTConfig: PVGeneratorConfig[],
    // PVGeneratorMPPT1Config: PVGeneratorConfig,
    // PVGeneratorMPPT2Config: PVGeneratorConfig,
    HasSizingResultError: boolean,
    CanWizardContinue: boolean,
    SizingValuesStatus: SizingValuesStatus,
    WizardStepStatus: Map<LowVoltagePlantSizingWizardSteps | MediumVoltageSizingWizardSteps, boolean>,
};

export const defaultStateSizing = () => {
    return {
        SizingName: '',
        PVModule: {} as PVModuleSimple,
        PVModuleData: {noct: 45, gref: 1000, tref: 25, gamma0: 1} as PVModule,
        PVModuleFilter: PVModuleFilter.all,
        PVModuleManufacturer: {} as PVModuleManufacturerSimple,
        PVModuleManufacturerName: {} as string,
        InverterFamily: {} as InverterFamily,
        InverterFamiles: [],
        Inverter: {} as InverterSimple,
        InverterData: {} as Inverter,
        AnalysisConditions: {globIrradMin: 200, globIrradMax: 1000} as AnalysisConditions,
        PVModuleSettings: {bifacialCoefficient: 0, vdcLosses: 0} as PVModuleSettings,
        PlantSizingWizardStep: {} as LowVoltagePlantSizingWizardSteps | MediumVoltageSizingWizardSteps,
        ReactiveRequerimentsPOI: {} as ReactiveRequerimentsPOI,
        ReactiveRequerimentsPOIName: {} as string,
        TransformerAndWiring: {} as TransformerAndWiring,
        TapChanger: {} as TapChanger,
        InverterTotalNumToAnalyzeFirst: 0,
        InverterTotalNumToAnalyze: 0,
        PVGeneratorMPPTConfigFirst: [],
        // PVGeneratorMPPT1ConfigFirst: {} as PVGeneratorConfigBasic,
        // PVGeneratorMPPT2ConfigFirst: {} as PVGeneratorConfigBasic,
        PVGeneratorMPPTConfig: [],
        // PVGeneratorMPPT1Config: {} as PVGeneratorConfig,
        // PVGeneratorMPPT2Config: {} as PVGeneratorConfig,
        HasSizingResultError: false,
        CanWizardContinue: false,
        SizingValuesStatus: SizingValuesStatus.WithoutStatus,
        WizardStepStatus: new Map<LowVoltagePlantSizingWizardSteps | MediumVoltageSizingWizardSteps, boolean>()
    }
};

export const stateSizing: StateSizing = defaultStateSizing();

const moduleSizing = {
    state: stateSizing,
    mutations: {
        setSizingValuesStatus(state: StateSizing, status: SizingValuesStatus) {
            state.SizingValuesStatus = status;
        },

        setPVModuleFilter(state: StateSizing, filter: PVModuleFilter) {
            state.PVModuleFilter = filter;
        },
        setPVModuleManufacturer(state: StateSizing, pvmodulemanufacturerid: number) {
            state.PVModuleManufacturer = {
                id: pvmodulemanufacturerid
            };
        },

        setPVModule(state: StateSizing, pvmoduleid: number) {
            state.PVModule = {
                id: pvmoduleid
            };
        },

        setPVModuleData(state: StateSizing, pvmodule: PVModule) {
            state.PVModuleData = pvmodule;
        },

        resetPVModuleData(state: StateSizing) {
            state.PVModuleData = {noct: 45, gref: 1000, tref: 25, gamma0: 1} as PVModule;
        },

        setPVModuleManufacturerName(state: StateSizing, pvModuleManufacturerName: string) {
            state.PVModuleManufacturerName = pvModuleManufacturerName;
        },

        setPVModuleSettings(state: StateSizing, pvmodulesettings: PVModuleSettings) {
            state.PVModuleSettings = pvmodulesettings;
        },

        setInverterFamily(state: StateSizing, inverterfamily: InverterFamily) {
            state.InverterFamily = inverterfamily;
        },

        setInverterFamilies(state: StateSizing, inverterfamilies: InverterFamily[]) {
            state.InverterFamiles = inverterfamilies;
        },

        setInverter(state: StateSizing, inverterid: number) {
            state.Inverter = {
                id: inverterid
            };
        },

        setInverterData(state: StateSizing, inverterdata: Inverter) {
            state.InverterData = inverterdata
        },

        setAnalysisConditions(state: StateSizing, analysisconditions: AnalysisConditions) {
            state.AnalysisConditions = analysisconditions;
        },

        setPlantSizingWizardStep(state: StateSizing, plantsizingwizardstep: LowVoltagePlantSizingWizardSteps | MediumVoltageSizingWizardSteps) {
            state.PlantSizingWizardStep = plantsizingwizardstep;
        },

        setPlantSizingName(state: StateSizing, plantsizingname: string) {
            state.SizingName = plantsizingname;
        },

        setReactiveRequerimentsPOI(state: StateSizing, reactiverequerimentspoi: ReactiveRequerimentsPOI) {
            state.ReactiveRequerimentsPOI = reactiverequerimentspoi;
        },

        setReactiveRequerimentsPOIName(state: StateSizing, name: string) {
            state.ReactiveRequerimentsPOIName = name;
        },

        setTransformerAndWiring(state: StateSizing, transformerandwiring: TransformerAndWiring) {
            state.TransformerAndWiring = transformerandwiring;
        },

        setTapChanger(state: StateSizing, tapchanger: TapChanger) {
            state.TapChanger = tapchanger;
        },

        setInverterTotalNumToAnalyze(state: StateSizing, invertertotalnumtoanalyze: number) {
            state.InverterTotalNumToAnalyze = invertertotalnumtoanalyze;
        },
        setInverterTotalNumToAnalyzeFirst(state: StateSizing, invertertotalnumtoanalyze: number) {
            state.InverterTotalNumToAnalyzeFirst = invertertotalnumtoanalyze;
        },

        resetInverterTotalNumToAnalyze(state: StateSizing) {
            state.InverterTotalNumToAnalyze = 0;
        },

        resetPVGeneratorMPPTConfig(state: StateSizing) {
            console.log('resetPVGeneratorMPPTConfig');
            state.PVGeneratorMPPTConfig.splice(0);
        },
        // resetPVGeneratorMPPT1Config(state: StateSizing) {
        //     state.PVGeneratorMPPT1Config = {} as PVGeneratorConfig;
        // },
        // resetPVGeneratorMPPT2Config(state: StateSizing) {
        //     state.PVGeneratorMPPT2Config = {} as PVGeneratorConfig;
        // },

        resetSizingsParameter(state: StateSizing) {
            // console.log('resetSizingsParameter');
            // if (state.InverterTotalNumToAnalyze !== 0) {
            state.InverterTotalNumToAnalyzeFirst = 0;
            state.InverterTotalNumToAnalyze = 0;
            // }
            state.PVGeneratorMPPTConfig.splice(0);
            // state.PVGeneratorMPPT1Config = {} as PVGeneratorConfig;
            // state.PVGeneratorMPPT2Config = {} as PVGeneratorConfig;
            state.PVGeneratorMPPTConfigFirst.splice(0);
            // state.PVGeneratorMPPT1ConfigFirst = {} as PVGeneratorConfig;
            // state.PVGeneratorMPPT2ConfigFirst = {} as PVGeneratorConfig;
        },

        setPVGeneratorMPPTConfigFirst(state: StateSizing, pvgeneratormpptconfig: PVGeneratorConfigBasic[]) {
            state.PVGeneratorMPPTConfigFirst = pvgeneratormpptconfig;
        },

        setPVGeneratorMPPTConfig(state: StateSizing, pvgeneratormpptconfig: PVGeneratorConfig[]) {
            state.PVGeneratorMPPTConfig = pvgeneratormpptconfig;
        },
        // setPVGeneratorMPPT1ConfigFirst(state: StateSizing, pvgeneratormpptconfig: PVGeneratorConfigBasic) {
        //     state.PVGeneratorMPPT1ConfigFirst = pvgeneratormpptconfig;
        // },
        //
        // setPVGeneratorMPPT1Config(state: StateSizing, pvgeneratormpptconfig: PVGeneratorConfig) {
        //     state.PVGeneratorMPPT1Config = pvgeneratormpptconfig;
        // },
        //
        // setPVGeneratorMPPT2ConfigFirst(state: StateSizing, pvgeneratormpptconfig: PVGeneratorConfigBasic) {
        //     state.PVGeneratorMPPT2ConfigFirst = pvgeneratormpptconfig;
        // },
        //
        // setPVGeneratorMPPT2Config(state: StateSizing, pvgeneratormpptconfig: PVGeneratorConfig) {
        //     state.PVGeneratorMPPT2Config = pvgeneratormpptconfig;
        // },

        setSizingResultError(state: StateSizing, hassizingresulterror: boolean) {
            state.HasSizingResultError = hassizingresulterror;
        },

        enableCanWizardContinue(state: StateSizing) {
            state.CanWizardContinue = true;
        },

        disableCanWizardContinue(state: StateSizing) {
            state.CanWizardContinue = false;
        },

        resetStateSizing(state: StateSizing) {
            Object.assign(state, defaultStateSizing());
        },

        enableFilledWizardStepStatus(state: StateSizing, step: LowVoltagePlantSizingWizardSteps | MediumVoltageSizingWizardSteps) {
            if (state.WizardStepStatus.has(step)) {
                state.WizardStepStatus.set(step, true);
            }
        },

        disableFilledWizardStepStatus(state: StateSizing, step: LowVoltagePlantSizingWizardSteps | MediumVoltageSizingWizardSteps) {
            if (state.WizardStepStatus.has(step)) {
                state.WizardStepStatus.set(step, false);
            }
        },

        setFilledLowVoltageWizardStepStatus(state: StateSizing) {
            const steps = Object.keys(LowVoltagePlantSizingWizardSteps).filter(k => isNaN(Number(k)));
            steps.splice(LowVoltagePlantSizingWizardSteps.CalculateAndSave, 1);
            steps.map((step, index) => {
                state.WizardStepStatus.set(
                    LowVoltagePlantSizingWizardSteps[LowVoltagePlantSizingWizardSteps[index] as keyof typeof LowVoltagePlantSizingWizardSteps], true);
            });
        },

        initLowVoltageWizardStepStatus(state: StateSizing) {
            const steps = Object.keys(LowVoltagePlantSizingWizardSteps).filter(k => isNaN(Number(k)));
            steps.splice(LowVoltagePlantSizingWizardSteps.CalculateAndSave, 1);
            steps.map((step, index) => {
                state.WizardStepStatus.set(
                    LowVoltagePlantSizingWizardSteps[LowVoltagePlantSizingWizardSteps[index] as keyof typeof LowVoltagePlantSizingWizardSteps], false);
            });
        },

        initMediumVoltageWizardStepStatus(state: StateSizing) {
            const steps = Object.keys(MediumVoltageSizingWizardSteps).filter(k => isNaN(Number(k)));
            steps.splice(MediumVoltageSizingWizardSteps.CalculateAndSave, 1);
            steps.map((step, index) => {
                state.WizardStepStatus.set(
                    MediumVoltageSizingWizardSteps[MediumVoltageSizingWizardSteps[index] as keyof typeof MediumVoltageSizingWizardSteps], false);
            });
        },

        // setWizardStepStatus(state: StateSizing, status: Map<LowVoltagePlantSizingWizardSteps | MediumVoltageSizingWizardSteps, boolean>) {
        //    state.WizardStepStatus = status;
        // }
    },
    actions: {
        resetSizingsParametersAndSteps(context: any) {
            console.log('cambia inversor/panel/condiciones analisis, se resetea parametros y estados pasos wizard');
            context.commit("resetSizingsParameter");
            context.commit("disableFilledWizardStepStatus", context.getters.isLowVoltageLevelPlantData ? LowVoltagePlantSizingWizardSteps.PVGenerator
                : MediumVoltageSizingWizardSteps.PVGenerator);
            context.commit("disableFilledWizardStepStatus", context.getters.isLowVoltageLevelPlantData ? LowVoltagePlantSizingWizardSteps.InvertersNumber
                : MediumVoltageSizingWizardSteps.InvertersNumber);
        }
    },
    getters: {
        getPVModuleFilter(state: StateSizing) {
            return state.PVModuleFilter;
        },
        getPVModule(state: StateSizing) {
            return state.PVModule;
        },
        getPVModuleData(state: StateSizing) {
            return state.PVModuleData;
        },
        getPVModuleManufacturer(state: StateSizing) {
            return state.PVModuleManufacturer;
        },

        getPVModuleManufacturerName(state: StateSizing) {
            return state.PVModuleManufacturerName;
        },

        getPVModuleSettings(state: StateSizing) {
            const pvmodulesettings = state.PVModuleSettings;
            pvmodulesettings.vdcLosses = +pvmodulesettings.vdcLosses.toFixed(2);
            if (pvmodulesettings.bifacialCoefficient) {
                pvmodulesettings.bifacialCoefficient = +pvmodulesettings.bifacialCoefficient?.toFixed(2);
            }
            return state.PVModuleSettings;
        },

        getInverterFamily(state: StateSizing) {
            return state.InverterFamily;
        },

        getInverterFamilies(state: StateSizing) {
            return state.InverterFamiles;
        },

        getInverter(state: StateSizing) {
            return state.Inverter;
        },

        getInverterData(state: StateSizing) {
            return state.InverterData;
        },

        getAnalysisConditions(state: StateSizing) {
            return state.AnalysisConditions;
        },

        getPlantSizingWizardStep(state: StateSizing) {
            return state.PlantSizingWizardStep;
        },

        getPlantSizingName(state: StateSizing) {
            return state.SizingName;
        },

        getReactiveRequerimentsPOI(state: StateSizing) {
            return state.ReactiveRequerimentsPOI;
        },

        getReactiveRequerimentsPOIName(state: StateSizing) {
            return state.ReactiveRequerimentsPOIName;
        },

        getTransformerAndWiring(state: StateSizing) {
            return state.TransformerAndWiring;
        },

        getTapChanger(state: StateSizing) {
            return state.TapChanger;
        },

        getInverterTotalNumToAnalyzeFirst(state: StateSizing) {
            return state.InverterTotalNumToAnalyzeFirst;
        },
        getInverterTotalNumToAnalyze(state: StateSizing) {
            return state.InverterTotalNumToAnalyze;
        },

        getPVGeneratorMPPTConfigFirst(state: StateSizing) {
            return state.PVGeneratorMPPTConfigFirst;
        },
        // getPVGeneratorMPPT1ConfigFirst(state: StateSizing) {
        //     return state.PVGeneratorMPPT1ConfigFirst;
        // },
        // getPVGeneratorMPPT2ConfigFirst(state: StateSizing) {
        //     return state.PVGeneratorMPPT2ConfigFirst;
        // },
        getPVGeneratorMPPTConfig(state: StateSizing) {
            return state.PVGeneratorMPPTConfig;
        },
        // getPVGeneratorMPPT1Config(state: StateSizing) {
        //     return state.PVGeneratorMPPT1Config;
        // },
        //
        // getPVGeneratorMPPT2Config(state: StateSizing) {
        //     return state.PVGeneratorMPPT2Config;
        // },

        getHasSizingResultError(state: StateSizing) {
            return state.HasSizingResultError;
        },

        getCanWizardContinue(state: StateSizing) {
            return state.CanWizardContinue;
        },

        getWizardStepStatus(state: StateSizing) {
            return state.WizardStepStatus;
        },

        isResetSizingStringsValuesStatus(state: StateSizing) {
            return state.SizingValuesStatus === SizingValuesStatus.ResetStringsValuesStatus;
        },
        isResetSizingInvertersValuesStatus(state: StateSizing) {
            return state.SizingValuesStatus === SizingValuesStatus.ResetInvertersValuesStatus;
        },
        isResetStringsValuesConfirmationStatus(state: StateSizing) {
            return state.SizingValuesStatus === SizingValuesStatus.ResetStringsValuesConfirmationStatus;
        },
        isResetInvertersValuesConfirmationStatus(state: StateSizing) {
            return state.SizingValuesStatus === SizingValuesStatus.ResetInvertersValuesConfirmationStatus;
        }
    }
}

export type StateGeneral = {
    variables: VariableUnits[];
}

export const defaultStateGeneral = () => {
    return {
        variables: []
    }
}

export const stateGeneral: StateGeneral = defaultStateGeneral();


const moduleGeneral = {
    state: stateGeneral,
    mutations: {
        setVariables(state: StateGeneral, variables: VariableUnits[]) {
            state.variables = variables;
        },

        resetStateGeneral(state: StateGeneral) {
            Object.assign(state, defaultStateGeneral());
        },
    },
    getters: {
        getTemperatureUnit(state: StateGeneral) {
            return state.variables.filter(v => v.name === "TemperatureUnit")[0].unit;
        },

        getIrradianceUnit(state: StateGeneral) {
            return state.variables.filter(v => v.name === "IrradianceUnit")[0].unit;
        },

        getLengthUnitm(state: StateGeneral) {
            return state.variables.filter(v => v.name === "LengthUnitm")[0].unit;
        },
        getVoltageUnit(state: StateGeneral) {
            return state.variables.filter(v => v.name === "VoltageUnit")[0].unit;
        },
        getPowerUnitValue(state: StateGeneral) {
            return state.variables.filter(v => v.name === "PowerUnitValue")[0].unit;
        },
        getCurrentUnit(state: StateGeneral) {
            return state.variables.filter(v => v.name === "CurrentUnit")[0].unit;
        },
        getPercentageUnit(state: StateGeneral) {
            return state.variables.filter(v => v.name === "PercentageUnit")[0].unit;
        },
        getLengthUnitM(state: StateGeneral) {
            return state.variables.filter(v => v.name === "LengthUnitm")[0].unit;
        },
        getMotorVersion(state: StateGeneral) {
            const motorversion = state.variables.filter(v => v.name === "MOTORVERSION")[0];
            return motorversion ? motorversion.unit : "";
        },
    }
}

export type StateNavigation = {
    HasPlantOrSizingDataStatus: HasPlantOrSizingStatus,
    PlantStatus: PlantStatus,
    PlantsCount: number,
    PlantSizingsName: string[],
    SizingStatus: SizingStatus,
    CloneSizingStatus: CloneSizingStatus;
    SelectedSizing: PlantSizingItemDTO,
    SavedSizingId: number,
    HasInverterFamilySelected: boolean,
    PVModuleDialog: PVModuleDialogStatus,
    PlantInfoDialog: PlantInfoDialogStatus,
    ShowConfirmationDialog: boolean,
    isReportExportProcess: boolean,
    isLoadingNewSizingWizard: boolean,
    LoginUserFormStatus: LoginUserFormStatus,
    ShowCookieSettingsDialog: boolean
};

export const defaultStateNavigation = () => {
    return {
        HasPlantOrSizingDataStatus: HasPlantOrSizingStatus.WithoutStatus,
        PlantStatus: PlantStatus.WithoutStatus,
        PlantsCount: 0,
        PlantSizingsName: [],
        CloneSizingStatus: CloneSizingStatus.WithoutStatus,
        SizingStatus: SizingStatus.WithoutStatus,
        SelectedSizing: {} as PlantSizingItemDTO,
        SavedSizingId: 0,
        HasInverterFamilySelected: false,
        PVModuleDialog: PVModuleDialogStatus.Hide,
        PlantInfoDialog: PlantInfoDialogStatus.Hide,
        ShowConfirmationDialog: false,
        isReportExportProcess: false,
        isLoadingNewSizingWizard: false,
        LoginUserFormStatus: LoginUserFormStatus.Login,
        ShowCookieSettingsDialog: false
    }
};


export const stateNavigation: StateNavigation = defaultStateNavigation();

const moduleNavigation = {
    state: stateNavigation,
    mutations: {
        setHasPlantOrSizingData(state: StateNavigation, hasdata: HasPlantOrSizingStatus) {
            state.HasPlantOrSizingDataStatus = hasdata;
        },

        setPlantSizingsName(state: StateNavigation, sizings: string[]) {
            state.PlantSizingsName = sizings;
        },

        setSelectedSizing(state: StateNavigation, selectedsizing: PlantSizingItemDTO) {
            state.SelectedSizing = selectedsizing;
        },

        setSavedSizingId(state: StateNavigation, sizingid: number) {
            state.SavedSizingId = sizingid;
        },

        setHasInverterFamilySelected(state: StateNavigation, selected: boolean) {
            state.HasInverterFamilySelected = selected;
        },
        resetHasInverterFamilySelected(state: StateNavigation) {
            state.HasInverterFamilySelected = false;
        },

        setPVModuleDialog(state: StateNavigation, status: PVModuleDialogStatus) {
            state.PVModuleDialog = status;
        },

        setPlantInfoDialog(state: StateNavigation, status: PlantInfoDialogStatus) {
            state.PlantInfoDialog = status;
        },

        setShowConfirmationDialog(state: StateNavigation, display: boolean) {
            state.ShowConfirmationDialog = display;
        },

        setShowCookieSettingsDialog(state: StateNavigation, display: boolean) {
            state.ShowCookieSettingsDialog = display;
        },

        setisReportExportProcess(state: StateNavigation, display: boolean) {
            state.isReportExportProcess = display;
        },
        setisLoadingNewSizingWizard(state: StateNavigation, isloading: boolean) {
            state.isLoadingNewSizingWizard = isloading;
        },

        resetStateNavigation(state: StateNavigation) {
            Object.assign(state, defaultStateNavigation());
        },

        setPlantStatus(state: StateNavigation, plantstatus: PlantStatus) {
            state.PlantStatus = plantstatus;
        },

        resetSelectedSizing(state: StateNavigation) {
            state.SelectedSizing = {} as PlantSizingItemDTO;
        },

        resetSavedSizing(state: StateNavigation) {
            console.log('resetSavedSizing');
            state.SavedSizingId = 0;
        },

        setPlantsCount(state: StateNavigation, plantscount: number) {
            state.PlantsCount = plantscount;
        },

        setSizingStatus(state: StateNavigation, sizingstatus: SizingStatus) {
            state.SizingStatus = sizingstatus;
        },
        setCloneSizingStatus(state: StateNavigation, status: CloneSizingStatus) {
            state.CloneSizingStatus = status;
        },

        setLoginUserFormStatus(state: StateNavigation, status: LoginUserFormStatus) {
            state.LoginUserFormStatus = status;
        },
    },
    getters: {
        getHasPlantOrSizingData(state: StateNavigation) {
            return state.HasPlantOrSizingDataStatus;
        },

        getPlantSizingsName(state: StateNavigation) {
            return state.PlantSizingsName;
        },

        hasSizings(state: StateNavigation) {
            return state.PlantSizingsName.length > 0;
        },

        getHasInverterFamilySelected(state: StateNavigation) {
            return state.HasInverterFamilySelected;
        },

        isDisplayPVModuleDialog(state: StateNavigation) {
            return state.PVModuleDialog === PVModuleDialogStatus.Display;
        },

        getPVModuleDialog(state: StateNavigation) {
            return state.PVModuleDialog;
        },

        getPlantInfoDialog(state: StateNavigation) {
            return state.PlantInfoDialog;
        },

        getShowConfirmationDialog(state: StateNavigation) {
            return state.ShowConfirmationDialog;
        },

        getShowCookieSettingsDialog(state: StateNavigation) {
            return state.ShowCookieSettingsDialog;
        },

        getisReportExportProcess(state: StateNavigation) {
            return state.isReportExportProcess;
        },
        getisLoadingNewSizingWizard(state: StateNavigation) {
            return state.isLoadingNewSizingWizard;
        },

        getSelectedSizing(state: StateNavigation) {
            return state.SelectedSizing;
        },

        getSavedSizing(state: StateNavigation) {
            return state.SavedSizingId;
        },

        hasPlants(state: StateNavigation) {
            return state.PlantsCount > 0;
        },

        isRefreshPlantStatus(state: StateNavigation) {
            return state.PlantStatus === PlantStatus.RefreshPlantList;
        },

        getPlantStatus(state: StateNavigation) {
            return state.PlantStatus;
        },

        isRemovePlantStatus(state: StateNavigation) {
            return state.PlantStatus === PlantStatus.RemovePlant;
        },

        isOKCloneSizingStatus(state: StateNavigation) {
            return state.CloneSizingStatus === CloneSizingStatus.OK;
        },
        isExistSizingNameCloneSizingStatus(state: StateNavigation) {
            return state.CloneSizingStatus === CloneSizingStatus.ExistSizingName;
        },
        isRemoveSizingStatus(state: StateNavigation) {
            return state.SizingStatus === SizingStatus.RemoveSizing;
        },

        isEditSizingStatus(state: StateNavigation) {
            return state.SizingStatus === SizingStatus.EditSizing;
        },

        isCloneSizingStatus(state: StateNavigation) {
            return state.SizingStatus === SizingStatus.CloneSizing;
        },

        isRefreshSizingStatus(state: StateNavigation) {
            return state.SizingStatus === SizingStatus.RefreshSizingList;
        },

        getSizingStatus(state: StateNavigation) {
            return state.SizingStatus;
        },
        getCloneSizingStatus(state: StateNavigation) {
            return state.CloneSizingStatus;
        },

        getLoginUserFormStatus(state: StateNavigation) {
            return state.LoginUserFormStatus;
        }
    }
}

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        moduleUser,
        modulePlant,
        moduleSizing,
        // moduleSizingResult,
        moduleNavigation,
        moduleGeneral,
        moduleAppState,
        modulePVModule
    },
});
