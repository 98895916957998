<template>
  <span>{{user.firstName}}</span>
</template>

<script lang="ts">
import {defineComponent, onMounted, ref, Ref} from "vue";
import {User} from "@/types/User";
import {useStore} from "vuex";
import APIDataServices from "@/services/APIDataServices";
import ResponseData from "@/types/ResponseData";

export default defineComponent({
  name: "User",
  setup() {
    const store = useStore();

    const user = ref(store.getters.getUser)

    return {
        user
    }
  }
});
</script>

<style scoped>

</style>
