import http from "@/http-common";
import {ENVIRONMENT} from "@/environments/environment";
import {User, UserEmail, UserEmailPassword} from "@/types/User";
// import token from "@/mocks/token.json";
// import validate from "@/mocks/validate.json";
// import variables from "@/mocks/variables.json";
// import plants from "@/mocks/plants.json";
// import sizings from "@/mocks/sizings.json";
// import plantsizing from "@/mocks/plantsizing.json";
// import families from "@/mocks/families.json";
// import inverter1 from "@/mocks/inverter1.json";
// import inverter2 from "@/mocks/inverter2.json";
// import pvmanufacturers from "@/mocks/pvmanufacturers.json";

class APIDataServices {

    getAPIURL(env: string) {
        return env === "DEV" ? "apidev" : env === "PRE" ? "apipre" : env === "PRO" ? "api" : "";
    }

    // isDebugEnvironment() {
    //     const host = this.getAPIURL(ENVIRONMENT.ENVIRONMENT);
    //     return host === "";
    // }

    getGeneralVariables(): Promise<any> {
        return http.get(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/general/variables`);
            // this.isDebugEnvironment() ? Promise.resolve({data: variables}) :
    }

    getPVModulesByManufacturerAndUser(manufacturerid: number, userid: number): Promise<any> {
        return http.get(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/pvmodule/user/${userid}/manufacturer/${manufacturerid}`);
    }

    getPVModulesManufacturers(): Promise<any> {
        return http.get(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/pvmodule/manufacturers`);
            // this.isDebugEnvironment() ? Promise.resolve({data: pvmanufacturers}) :
    }

    getPVModuleType(): Promise<any> {
        return http.get(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/pvmodule/pvmoduletype`);
    }

    getInvertersByFamily(familyid: number): Promise<any> {
        // const data = familyid === 1 ? inverter1 : familyid === 2 ? inverter2 : null;
        return http.get(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/inverter/${familyid}`);
            // this.isDebugEnvironment() ? Promise.resolve({data: data}) :
    }

    getInvertersFamilies(): Promise<any> {
        return http.get(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/inverter/families`);
        // this.isDebugEnvironment() ? Promise.resolve({data: families}) :
    }

    // getInvertersMediumVoltage(): Promise<any> {
    //     return http.get(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/inverter/mediumvoltage`);
    // }

    plantSizingLowVoltageUpdate(sizingid: number, data: any): Promise<any> {
        return http.put(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/plantsizinglowvoltage/sizing/${sizingid}`, data);
    }
    plantSizingLowVoltageCalculateNew(data: any): Promise<any> {
        return http.post(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/plantsizinglowvoltage`, data);
    }

    plantSizingLowVoltageCalculateExist(data: any): Promise<any> {
        return http.post(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/plantsizinglowvoltage/plantsizing`, data);
        // this.isDebugEnvironment() ? Promise.resolve({data: plantsizing}) :
    }

    plantSizingMediumVoltageCalculateExist(data: any): Promise<any> {
        return http.post(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/plantsizingmediumvoltage/plantsizing`, data);
    }

    plantSizingLowVoltageDelete(plantid: number, sizingid: number): Promise<any> {
        return http.delete(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/plantsizinglowvoltage/plant/${plantid}/sizing/${sizingid}`);
    }

    plantSizingLowVoltageClone(plantid: number, sizingid: number): Promise<any> {
        return http.post(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/plantsizinglowvoltage/clone/plant/${plantid}/sizing/${sizingid}`);
    }

    plantSizingLowVoltageEdit(data: any): Promise<any> {
        return http.post(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/plantsizinglowvoltage/edit`, data);
    }

    plantSizingLowVoltageReport(language: string, data: any): Promise<any> {
        return http.post(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/plantsizinglowvoltage/report/${language}`,
            data,
            {
                responseType: 'arraybuffer',
                headers: {
                    Accept: 'application/pdf',
                },
            });
    }

    plantSizingMediumVoltageDelete(plantid: number, sizingid: number): Promise<any> {
        return http.delete(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/plantsizingmediumvoltage/plant/${plantid}/sizing/${sizingid}`);
    }

    mediumvoltagesizingcalculate(data: any): Promise<any> {
        return http.post(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/plantsizingmediumvoltage`, data);
    }

    getCountryGridCodeTypes(): Promise<any> {
        return http.get(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/countrygridcode`);
    }

    getWiringLostTypes(): Promise<any> {
        return http.get(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/wiringlosttype`);
    }

    getPlantsByUser(userid: number): Promise<any> {
        return http.get(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/user/${userid}/plants`);
        // this.isDebugEnvironment() ? Promise.resolve({data: plants}) :
    }

    // getUserToken(userdata: UserSimple): Promise<any> {
    //     return http.post(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/user/token`, userdata);
    //     // this.isDebugEnvironment() ? Promise.resolve({data: token}) :
    // }

    getUserTokenValidate(userdata: UserEmailPassword): Promise<any> {
        return http.post(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/user/tokenvalidate`, userdata);
        // this.isDebugEnvironment() ? Promise.resolve({data: token}) :
    }

    // validateUserData(userdata: UserSimple): Promise<any> {
    //     return http.post(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/user/validate`, userdata);
    //     // this.isDebugEnvironment() ? Promise.resolve({data: validate}) :
    // }

    recoveryUserPwd(userEmail: UserEmail): Promise<any> {
        return http.post(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/user/recovery`, userEmail);
    }

    registerUser(user: User): Promise<any> {
        return http.post(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/user/registeruser`, user);
    }

    // getPlantById(plantid: number): Promise<any> {
    //     return http.get(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/plant/${plantid}`);
    // }

    deletePlantById(plantid: number): Promise<any> {
        return http.delete(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/plant/${plantid}`);
    }

    getSizingsByPlantId(plantid: number): Promise<any> {
        return http.get(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/plant/${plantid}/sizings`);
        // this.isDebugEnvironment() ? Promise.resolve({data: sizings}) :
    }

    registerplant(data: any): Promise<any> {
        return http.post(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/plant`, data);
    }

    registerpvmodule(data: any): Promise<any> {
        return http.post(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/pvmodule`, data);
    }

    updatepvmodule(data: any, userid: number, pvmoduleid: number): Promise<any> {
        return http.put(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/pvmodule/user/${userid}/pvmodule/${pvmoduleid}`, data);
    }

    deletePVModule(userid: number, pvmoduleid: number): Promise<any> {
        return http.delete(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/pvmodule/user/${userid}/pvmodule/${pvmoduleid}`);
    }

    // createReport(): Promise<any> {
    //     return http.get(`/${this.getAPIURL(ENVIRONMENT.ENVIRONMENT)}/report/create`,
    //         {
    //             responseType: 'arraybuffer',
    //             headers: {
    //                 Accept: 'application/pdf',
    //             },
    //         });
    // }
}

export default new APIDataServices();
